.holismo-switch {
  .mdc-switch__ripple {
    display: none;
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background: #A6A6A6 !important;
  }

  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #48494A !important;
  }
}
