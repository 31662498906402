.active-item {
  svg {
    background: transparent;
  }
  .node-group:not(.active-group) {
    filter: opacity(70%) grayscale(0.4)
  }
}

.chart  {
  svg {
    max-width: 100%;
  }
}
