@import "toast";
@import "dnd";
@import "life-area-table";
@import "life-activity-table";
@import "life-wheel";
@import "calendar";
@import "toggle";
@import "editor";

body {
  overscroll-behavior-y: contain;
}
// prevent highlight when tapping on svg notes in charts
.node-group {
  -webkit-tap-highlight-color: transparent;
}

#newsfeed-launcher .mat-badge-content {
  background: #9C27B0 !important;
}

.ngx-background-spinner  {
  transform: translateX(-18px) !important;
}
