
.active-element {
  //.page-layout {
  //  width: 1px;
  //}

  //&:after {
  //  content: '';
  //  position: fixed;
  //  width: 100%;
  //  height: 100%;
  //  background: rgba(0, 0, 0, 0.2);
  //  left: 48px;
  //}

  //.layout-header, .layout-sidenav, .life-header {
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background: rgba(0, 0, 0, 0.2);
  //  }
  //}

  //.life-area-container, .life-state-container, .life-specifics-container, .life-activity-container {
  //  outline: none;
  //
  //  &:hover {
  //    outline: none !important;
  //  }
  //
  //  &:focus-visible {
  //    outline: none !important;
  //  }
  //}
  //
  //.life-state-container, .life-specifics-container, .life-activity-container {
  //  background: rgb(215, 209, 209);
  //
  //  &:hover {
  //    background: #fff;
  //  }
  //}

  //.life-area-container:not(:has(.life-state-container:hover, .life-specifics-container:hover, .life-specifics-list:hover, .life-states-list:hover)):hover {
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background-color: rgba(255, 255, 255, 0.4);
  //    z-index: -1;
  //    border-radius: 8px;
  //  }
  //}
}

.life-area-container:has(.life-state-container:hover, .life-specifics-container:hover, .life-specifics-list:hover, .life-states-list:hover):hover {
  outline: none !important;
}

//.life-area-container, .life-state-container, .life-specifics-container, .life-activity-container {
//  &:focus-visible {
//    outline: none !important;
//  }
//}
//
//
//.area-highlighted {
//  &:after {
//    content: '';
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-color: rgba(255, 255, 255, 0.4);
//    z-index: -1;
//    border-radius: 8px;
//  }
//}
