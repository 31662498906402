/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;

  .life-area-container {
    height: 64px !important;
    padding-bottom: 0 !important;
    justify-content: center;
    .life-states-list {
      display: none;
    }
  }

  .life-state-container {
    + .life-specifics-list {
      display: none;
    }
  }

  .life-specifics-container {
    .life-specifics-connector {
      display: none;
    }
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);

  .life-state-container {
    + .life-specifics-list {
      display: none;
    }
  }

  .life-area-container {
    height: 64px !important;
    padding-bottom: 0 !important;
    .life-states-list {
      display: none;
    }
  }

  .life-specifics-container {
    height: 40px !important;
  }
}

.gu-forbidden  {
  .gu-mirror  {
    .life-state-container, .life-specifics-container {
      background: #E91E63 !important;
    }
  }
}

.life-specifics-list {
  hl-life-specific {
    .life-specifics-connector {
      height: 45px;
    }

    &:first-child {
      .life-specifics-connector {
        height: 24px;
      }
    }
  }
}

