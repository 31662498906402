.holismo-calendar {
  .mat-calendar-header {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 10px;
  }

  .mat-calendar-controls {
    margin-bottom: 3px;

    .mat-calendar-previous-button {
      order: 0;
    }

    .mat-calendar-period-button {
      order: 1;
      margin-left: auto;
      margin-right: auto;
    }

    .mat-calendar-next-button {
      order: 2;
    }

    .mat-calendar-spacer {
      display: none;
    }
  }

  .mat-calendar-table-header-divider::after {
    display: none;
  }

  .mat-calendar-body-selected {
    background: rgba(156, 39, 176, 1) !important;
    color: #fff !important;
  }
}

.life-start-date, .has-input-date {
  .mat-calendar-body-cell-content {
    background: rgba(156, 39, 176, 0.7);
    border-radius: 100%;
  }


  &:before, &:after {
    display: none !important;
  }

  span {
    border: none;
    color: #fff;
  }
}

.has-input-date {
  .mat-calendar-body-cell-content {
    background: rgba(156, 39, 176, 0.2);
    border-radius: 100%;
  }

  span {
    border: none;
    color: #607D8B;
  }
}

.holismo-interval-calendar {
  .mat-calendar-body-label, .mat-calendar-header, .mat-calendar-table-header {
    display: none;
  }
}
