/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply inline-flex items-center p-3 mt-0 bg-black/70 border h-12 rounded-lg text-white disabled:opacity-30
  }

  .btn-secondary {
    @apply inline-flex items-center p-3 mt-0 bg-black/30 border h-12 rounded-lg text-white disabled:opacity-30 hover:bg-holismo-grey
  }

  .btn-error {
    @apply inline-flex items-center p-3 mt-0 bg-white border border-holismo-pink h-12 rounded-lg text-holismo-pink hover:text-white hover:bg-holismo-pink disabled:opacity-30
  }

  .popup-menu {
    @apply w-[214px] flex flex-col shadow p-3 bg-white text-sm z-[10000] text-menugray rounded-b-md
  }

  .side-nav-btn {
    @apply w-9 h-9 flex items-center justify-center rounded-[5px] hover:bg-filtershover hover:text-white disabled:opacity-30
  }

  .popup-menu-btn {
    @apply h-9 flex items-center justify-start cursor-pointer px-2 hover:bg-gray-500/20 hover:text-gray-400 rounded-md disabled:opacity-30
  }

  .popup-menu-btn-trash {
    @apply popup-menu-btn text-holismo-pink hover:text-white hover:bg-holismo-pink disabled:opacity-30
  }

  .popup-menu-btn-life {
    @apply h-9 flex items-center justify-start cursor-pointer px-2 gap-x-2 rounded-md relative overflow-hidden disabled:opacity-30
  }

  .drawer-header {
    @apply sticky w-full top-0 bg-drawer py-5 z-20 flex flex-col text-gray-600
  }

  .tab-container {
    @apply flex w-full mt-8 bg-gray-300 text-sm rounded-lg items-stretch justify-evenly p-1 top-[1px] relative
  }

  .tab {
    @apply p-2 flex-1 rounded-lg text-center cursor-pointer
  }
}

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~quill/dist/quill.snow.css";

@import "@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import "assets/styles";

